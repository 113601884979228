<template>
  <router-view>
    <template slot="titlebreadcrumb">
      <slot name="breadcrumb"></slot>
    </template>
  </router-view>
</template>

<script>
export default {};
</script>
